import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import SubTitle from "../utilities/SubTitle";
import { useTranslation } from "react-i18next";

import CategoryCard from "../Category/CategoryCard";
import CompanyPageHook from "../../hooks/category/CompanyPageHook";

const HomeCompany = () => {
  // const [, loading, colors, categoryWithout] = HomeCatHook();
  const [companies, loading, loadingSpinner, colors] = CompanyPageHook();
  const { t } = useTranslation();
  // console.log("category");
  //console.log(category);

  return (
    <Container>
      <SubTitle title={t("companies")} path="/allcategory" />
      <Col className="mb-2 d-flex justify-content-center random-container">
        {loading === false ? (
          companies && companies.length !== 0 ? (
            companies.slice(0, 10).map((el, index) => {
              return (
                <CategoryCard
                  key={el.companyId}
                  title={el.companyName}
                  id={el.companyId}
                  img={el.companyImage}
                  background={colors[index]}
                  type={"company"}
                />
              );
            })
          ) : (
            <div className="text-center fw-bold">{t("no categories")} </div>
          )
        ) : (
          <Spinner animation="border" variant="primary" />
        )}
      </Col>
    </Container>
  );
};

export default HomeCompany;
