import React from "react";
import { Card, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import baseURL from "../../Api/baseURL";

const CategoryCard = ({ background, img, title, id, type }) => {
  const navigate = useNavigate();

  const data = { img, title };

  return (
    // <Col
    //   className="my-2 d-flex justify-content-around categoty-card marginSubCategory"
    // >
    //   <div className="allCard mb-2 ">
    //     <Link to={`/category/subcategory/${id}`} style={{ textDecoration: 'none' }}>
    //       <div
    //                   className="displayFlex"

    //         // className="categoty-card "
    //         // style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}
    //       >

    //         <img alt="categotyCardImg" src={img} className="categoty-card-img" />
    //       <p className="categoty-card-text my-1" >{title}</p>
    //       </div>
    //     </Link>
    //   </div>
    // </Col>

    <Col
      xs="4"
      sm="4"
      md="3"
      lg="2"
      className="d-flex forMobile catCard"
      style={{}}
    >
      <Card
        onClick={() =>
          navigate(
            `/products/${type === "catogry" ? "category" : "company"}/${id}`,
            {
              state: data,
            }
          )
        }
        className="my-1"
        style={{
          borderRadius: "8px",
          border: "none",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 2px 2px 0 rgba(151,151,151,0.5)",
          justifyContent: "center",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <Fade>
          <Card.Img
            // onClick={() => navigate(`/category/subcategory/${id}`)}
            style={{
              height: "60px",
              width: "95%",
              objectFit: "contain",
              cursor: "pointer",
              alignSelf: "center",
            }}
            src={baseURL.getUri() + img}
            alt={title}
          />
          <p
            className="categoty-card-text my-1"
            style={{
              padding: "5px",
            }}
          >
            {title}
          </p>
        </Fade>
      </Card>
    </Col>
  );
};

export default CategoryCard;
