import {
  ADD_TO_CART,
  GET_USER_CART_ITEMS,
  DELETE_ALL_CART_ITEMS,
  REMOVE_ONE_ITEM,
  UPDATE_ITEM_CART,
} from "../type";
import { useInsertData } from "../../database/useInsertData";
import { useGetData } from "../../database/useGetData";
import useDeleteData from "../../database/useDeleteData";
import { useUpdateData } from "../../database/useUpdateData";

export const addProductToCart = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/Cart/Insert/Cart`, body);
    dispatch({
      type: ADD_TO_CART,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_TO_CART,
      payload: error,
    });
  }
};

export const getAllCartItems = (userId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/Cart/Select/Cart/${userId}`);
    dispatch({
      type: GET_USER_CART_ITEMS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_CART_ITEMS,
      payload: error,
    });
  }
};

export const removeAllCartItems = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/cart/remove/${id}`);
    dispatch({
      type: DELETE_ALL_CART_ITEMS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ALL_CART_ITEMS,
      payload: error,
    });
  }
};

export const removeOneItem = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/Cart/Delete/Cart/${id}`);
    dispatch({
      type: REMOVE_ONE_ITEM,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_ONE_ITEM,
      payload: error,
    });
  }
};

export const updateItemCart = (id, quantity) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/Cart/Update/Cart/${id}/${quantity}`
    );
    dispatch({
      type: UPDATE_ITEM_CART,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ITEM_CART,
      payload: error,
    });
  }
};
