import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hooks/useNotification";
import { createNewUser } from "../../redux/actions/authActions";

const RegisterHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onChangeMobile = (e) => {
    setMobile(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  // const validationValues = () => {};
  const res = useSelector((state) => state.authReducers.createUser);

  const onSubmit = async () => {
    if (name === "") {
      notify("من فضلك ادخل الاسم ", "error");
      return;
    }

    if (mobile.length <= 9) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل البريد الالكتروني", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل كلمة المرور", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك ادخل تاكيد كلمة المرور", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("من فضلك تاكد من كلمه المرور", "error");
      return;
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("UserName", name);
      formData.append("UserPhone", mobile);
      formData.append("UserEmail", email.toLocaleLowerCase());
      formData.append("UserPassword", password);
      await dispatch(createNewUser(formData));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (loading === false) {
      if (res) {
        console.log("reqqqqqqqqqqqqqqqqq");
        console.log(res);

        if (res.status == 200) {
          notify("تم تسجيل الحساب بنجاح", "success");
          // setLoading(true);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
        if (res.data) {
          if (res.data === "هذا الايميل موجود بالفعل") {
            notify(" البريد الالكتروني  موجود بالفعل", "error");
          }
        }
      }
    }
  }, [loading]);

  return [
    name,
    mobile,
    email,
    password,
    confirmPassword,
    loading,
    onChangeName,
    onChangeMobile,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
  ];
};

export default RegisterHook;
