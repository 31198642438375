import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DeleteCartHook from "../../hooks/cart/DeleteCartHook";
import { ToastContainer } from "react-toastify";
import notify from "../../hooks/useNotification";
import { BiLeftArrowCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
// import { getOneUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { payInvoiceOrder } from "../../redux/actions/orderAction";
import UserMapLocation from "../User/UserMapLocation";
import { getAllCartItems } from "../../redux/actions/cartAction";

const CartCheckout = ({
  totalPrice,
  totalPriceAfterDiscount,
  couponNameAfterApplay,
  cartItems,
  vendorId,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [handleDeletCart] = DeleteCartHook();

  const [loading, setLoading] = useState(true);

  const currencytype = t("SAR");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    requestLocation();
  };

  const [name, setName] = useState("");

  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [closeSign, setCloseSign] = useState("");

  const onChangeName = (e) => {
    e.persist();
    setName(e.target.value);
  };

  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  // const onChangeCloseSign = (e) => {
  //   e.persist();
  //   setCloseSign(e.target.value);
  // };
  const onChangeLatLang = (lat, lang) => {
    setLatitude(lat);
    setLongitude(lang);
  };

  const ref = useRef(null);

  const products = cartItems?.map((el) => {
    return { productId: el.productId };
  });

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleCheckout = () => {
    setShow(true);
    // navigate("/payment");
    // await requestLocation();
    // setShow();
    // setLoading(true);
    // await dispatch(
    //   payInvoiceOrder({
    //     userId: user.userId,
    //     invoiceUserAdress: "test",
    //     invoiceUserPhone: user.userPhone,
    //     invoiceUserLocation: "test",
    //     invoiceNote: "test",
    //     products: products,
    //   })
    // );
    // setLoading(false);
    // // to="/order/paymethoud"
    // if (cartItems.length >= 1) {
    //   navigate("/order/paymethoud", { state: { shippingCost: 22 } });
    // } else {
    //   notify(t("Please add products to the cart"), "warn");
    // }
  };

  const onSubmit = async () => {
    navigate("/payment", {
      state: {
        amount: totalPrice,
        userId: user.userId,
        invoiceUserAdress: name,
        invoiceUserPhone: mobile,
        invoiceUserLocation: "location",
        invoiceNote: address ? address : "  notes   ",
        products: products,
      },
    });

    // setLoading(true);
    // await dispatch(
    //   payInvoiceOrder({
    //     userId: user.userId,
    //     invoiceUserAdress: name,
    //     invoiceUserPhone: mobile,
    //     invoiceUserLocation: "location",
    //     invoiceNote: address,
    //     products: products,
    //   })
    // );
    // setLoading(false);
  };

  const payResponse = useSelector((state) => state.orderReducer.payInvoice);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setLocation({ latitude, longitude });
    setError(null);
  };

  const handleError = (error) => {
    setError(error.message);
    setLocation(null);
  };

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      notify("Geolocation is not supported by this browser.");
    }
  };
  requestLocation();
  // console.log("location");
  // console.log(location);
  useEffect(() => {
    if (loading === false) {
      if (payResponse) {
        console.log("payResponse");
        console.log(payResponse);
        dispatch(getAllCartItems(user.userId));
        notify(t("Payment completed successfully"), "success");
        setTimeout(() => {
          navigate("/user/allorders");
        }, 1500);
      }
    }
  }, [loading, payResponse]);

  return (
    <Row className=" d-flex justify-content-center  cart-checkout">
      <Modal show={show} onHide={handleClose}>
        <div style={{ width: "100%" }}>
          <Modal.Header>
            <Modal.Title>
              <div className="admin-content-text font">
                {" "}
                {t("add new address")}{" "}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col sm="8" style={{ width: "100%" }}>
                  <label htmlFor="address">{t("Title name")}</label>
                  <input
                    id="address"
                    value={name}
                    onChange={onChangeName}
                    type="text"
                    className="input-form d-block mt-2 px-3"
                    placeholder={t("Title nam")}
                  />
                  <>
                    {/* <label htmlFor="closeSign" className="mt-2">
                      {t("nearest mark")}
                    </label>
                    <textarea
                      id="closeSign"
                      value={closeSign}
                      onChange={onChangeCloseSign}
                      className="input-form-area p-2 mt-2"
                      rows="2"
                      cols="50"
                      placeholder={t("nearest mark")}
                    /> */}
                    <label htmlFor="phone" className="mt-2">
                      {" "}
                      {t("phone number")}
                    </label>
                    <input
                      id="phone"
                      value={mobile}
                      onChange={onChangeMobile}
                      type="text"
                      className="input-form d-block mt-2 px-3"
                      placeholder={t("phone number")}
                    />
                  </>

                  <label htmlFor="notes" className="mt-2">
                    {" "}
                    {t("Notes")}
                  </label>
                  <textarea
                    id="notes"
                    value={address}
                    onChange={onChangeAddress}
                    className="input-form-area p-2 my-2"
                    rows="4"
                    cols="50"
                    placeholder={t("Notes")}
                  />
                  {/* <iframe
         width="100%"
         height="250"
         id="gmap_canvas"
         src={`https://maps.google.com/maps?q=${latitude},${longitude}&iwloc=&output=embed`}
         frameborder="0"
         scrolling="no"
         marginheight="0"
         marginwidth="0"
       ></iframe> */}
                  {/*    <UserMapLocation
                    latitude={latitude}
                    longitude={longitude}
                    onPress={onChangeLatLang}
                  />*/}
                </Col>
              </Row>

              <ToastContainer />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="font d-inline mt-2"
              variant="secondary"
              onClick={handleClose}
              style={{ color: "#000" }}
            >
              {t("retreat")}
            </Button>
            <button
              onClick={onSubmit}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: "#d53a59" }}
            >
              {t("add adress")}
            </button>
          </Modal.Footer>
        </div>
      </Modal>
      <title>{` ${t("shopping cart")} - AlTamyez`} </title>

      <LoadingBar color={"#d53a59"} ref={ref} shadow={true} height={4} />

      <Col xs="12" className="d-flex  flex-column  ">
        <div className="product-price d-inline w-100 my-1  border">
          {`${totalPrice} ${currencytype}` || 0}
        </div>

        <button
          onClick={handleCheckout}
          className="product-cart-add w-100 px-2"
          style={{ backgroundColor: "#d53a59" }}
        >
          {t("Checkout")}
        </button>
        <button
          onClick={() => navigate("/")}
          className="product-cart-add w-100 px-2 mt-1"
          style={{ backgroundColor: "#d53a59" }}
        >
          {t("Continue shopping")}
        </button>
        {/* <Button
          onClick={handleDeletCart}
          variant="secondary"
          className="w-100 px-2 my-2 "
        >
          {t("delete cart")}
          <i className="fa fa-trash px-2" />
        </Button> */}
      </Col>
      <ToastContainer />
    </Row>
  );
};

export default CartCheckout;
