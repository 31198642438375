import React from "react";
import { Row } from "react-bootstrap";
import UserGetOrdersHook from "../../hooks/user/UserGetOrdersHook";
import { DataGrid } from "@mui/x-data-grid";
import Cookies from "js-cookie";

import UserAllOrderItem from "./UserAllOrderItem";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LoadingSpinner from "../utilities/LoadingSpinner";

const UserAllOrder = () => {
  const [orderData, loading] = UserGetOrdersHook();

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Row className="justify-content-between">
      {loading === false ? (
        orderData?.length >= 1 ? (
          orderData.map((el) => {
            return <UserAllOrderItem key={el.invoiceId} orderItem={el} />;
          })
        ) : (
          <h6>{t("no orders yet")}</h6>
        )
      ) : (
        <LoadingSpinner />
      )}
    </Row>
  );
};

export default UserAllOrder;
