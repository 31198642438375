import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";

const LoadingSpinner = () => {
  return (
    <div className="loaddingSpinner">
      {/* <FidgetSpinner
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
        ballColors={['#ff0000', '#00ff00', '#0000ff']}
        backgroundColor="#F4442E"
      /> */}
      <Oval
        height={80}
        width={80}
        color={"#d53a59"}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default LoadingSpinner;
