import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneProduct,
  getProductsByCategory,
} from "../../redux/actions/productsAction";

const ViewProductDetails = (id) => {
  const dispatch = useDispatch();

  const [productItem, setProductItem] = useState("");
  const [loading, setLoading] = useState(true);

  // const [images,setImages] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    dispatch(getOneProduct(id));
    setLoading(false);
  }, [dispatch, id]);

  const oneProduct = useSelector((state) => state.allProduct.oneProduct);

  // console.log("oneProduct");
  // console.log(oneProduct);

  const similarProducts = useSelector(
    (state) => state.allProduct.productsByCategory
  );

  useEffect(() => {
    if (loading === false) {
      if (oneProduct && oneProduct.productName) {
        setProductItem(oneProduct);
        // let titleAr = "AlTamyez";
        // let itemName = "";
        // itemName = oneProduct.productName;
        // document.title = ` ${titleAr} -  ${itemName || ""}`;
      }
    }
  }, [loading, oneProduct]);

  // let item = [];
  // if (oneProduct) item = oneProduct;
  // else item = [];

  useEffect(() => {
    if (oneProduct.categoryId) {
      dispatch(getProductsByCategory(oneProduct.categoryId, 10, 1));
    }
  }, [dispatch, oneProduct]);

  // let images = [];
  // if (oneProduct.images) {
  //   images = oneProduct.images.map((img) => {
  //     return { original: img };
  //   });
  // } else {
  //   images = [{ original: "" }];
  // }

  // let prod = [];
  // if (similarProd && similarProd.data) prod = similarProd.data.slice(0, 4);
  // else prod = [];

  return [productItem, similarProducts];
};

export default ViewProductDetails;
