import { GET_ALL_SLIDER } from "../type";
const inital = {
  allSlider: [],
};

const sliderReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_SLIDER:
      return {
        ...state,
        allSlider: action.payload,
      };

    default:
      return state;
  }
};
export default sliderReducer;
