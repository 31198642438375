import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import categoryReducer from "./categoryReducer";
import sliderReducer from "./sliderReducer";
import authReducers from "./authReducers";
import productReducer from "./productReducer";
import agentReducers from "./agentReducers";
import termsPolicyAboutReducer from "./termsPolicyAboutReducer";
import userAddressReducer from "./userAddressReducer";
import orderReducer from "./orderReducer";

export default combineReducers({
  cartReducer: cartReducer,
  allCategory: categoryReducer,
  sliderReducer: sliderReducer,
  authReducers: authReducers,
  allProduct: productReducer,
  agentReducers: agentReducers,
  termsPolicyAboutReducer: termsPolicyAboutReducer,
  userAddressReducer: userAddressReducer,
  orderReducer: orderReducer,
});
