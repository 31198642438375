import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";

import SubTitle from "../utilities/SubTitle";
import ProductCard from "./ProductCard";
import LoadingSpinner from "../utilities/LoadingSpinner";
import { useTranslation } from "react-i18next";

const CardProductsContainer = ({
  title,
  btntitle,
  path,
  products,
  fourImage,
}) => {
  // const [favProduct] = CardContainerHook();
  const [loadingSpinnerTime, setLoadingSpinnerTime] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (products) {
      setTimeout(setLoadingSpinnerTime(false), 1000);
    }
  }, [products]);
  // console.log("productssssssssssssssssssss");
  // console.log(products);
  return (
    <Container>
      {title !== "" && (
        <SubTitle title={title} btntitle={btntitle} path={path} />
      )}
      <Row className="my-1 d-flex justify-content-md-start justify-content-center">
        {loadingSpinnerTime === true ? (
          <LoadingSpinner />
        ) : (
          <>
            {products
              ? products.map((item) => (
                  <ProductCard
                    // favProduct={favProduct}
                    key={item?.productId}
                    item={item}
                    fourImage={fourImage}
                  />
                ))
              : ""}

            {products.length === 0 && loadingSpinnerTime === false ? (
              <h6 style={{ textAlign: "center", marginTop: "10px" }}>
                {t("no products")}{" "}
              </h6>
            ) : (
              ""
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default CardProductsContainer;
