import { GET_ALL_CATEGORIES, GET_ALL_COMPANIES } from "../type";
import { useGetData } from "../../database/useGetData";

//get all categories
export const getAllCategories = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/Category/Select/All/Category`);
    dispatch({
      type: GET_ALL_CATEGORIES,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CATEGORIES,
      payload: error.response,
    });
  }
};

//get all companies
export const getAllCompanies = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/Company/Select/All/Company`);
    dispatch({
      type: GET_ALL_COMPANIES,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPANIES,
      payload: error.response,
    });
  }
};
