import React, { useState, useEffect } from "react";
// import ViewSearchProductHooks from "../product/ViewSearchProductHooks";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ViewSearchProductHooks from "../product/ViewSearchProductHooks";

const NavBarSearchHook = () => {
  const [searchWord, setSearchWord] = useState("");
  const [, , , , getProduct] = ViewSearchProductHooks(searchWord);

  // const [, , , getProduct] = ViewSearchProductHooks(searchWord);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const onChangeSearch = (e) => {
    setSearchWord(e.target.value);
    console.log("e.target.value");
    console.log(e.target.value);
    if (location.pathname !== "/products") {
      navigate("/products");
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (location.pathname === "/products") {
        getProduct();
      }
      // getProduct();
    }, 1000);
  }, [location.pathname, searchWord]);

  return [onChangeSearch, searchWord];
};

export default NavBarSearchHook;
