export const ADD_TO_CART = "ADD_TO_CART";
export const GET_USER_CART_ITEMS = "GET_USER_CART_ITEMS";
export const DELETE_ALL_CART_ITEMS = "DELETE_ALL_CART_ITEMS";
export const REMOVE_ONE_ITEM = "REMOVE_ONE_ITEM";
export const UPDATE_ITEM_CART = "UPDATE_ITEM_CART";

export const GET_ERROR = "GET_ERROR";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_ALL_SLIDER = "GET_ALL_SLIDER";

export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const LOGIN_USER = "LOGIN_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_ALL_NEW_PRODUCTS = "GET_ALL_NEW_PRODUCTS";
export const GET_ALL_AGENTS = "GET_ALL_AGENTS";
export const GET_POLICY_TERMS_ABOUT = "GET_POLICY_TERMS_ABOUT";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_ALL_PRODUCTS_CATEGORY = "GET_ALL_PRODUCTS_CATEGORY";
export const GET_ALL_PRODUCTS_COMPANY = "GET_ALL_PRODUCTS_COMPANY";
export const GET_FOUR_IMAGE_PRODUCTS = "GET_FOUR_IMAGE_PRODUCTS";

export const USER_ADD_ADDRESS = "USER_ADD_ADDRESS";
export const GET_USER_ADDRESS_BY_ID = "GET_USER_ADDRESS_BY_ID";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const USER_EDIT_ADDRESS = "USER_EDIT_ADDRESS";
export const GET_ONE_ADDRESS = "GET_ONE_ADDRESS";
export const GET_ONE_USER = "GET_ONE_USER";
export const GET_ALL_ORDERS_BY_USER = "GET_ALL_ORDERS_BY_USER";
export const GET_ONE_ORDER = "GET_ONE_ORDER";
export const PAY_INVOICE = "PAY_INVOICE";
export const SEARCH_FOR_PRODUCT = "SEARCH_FOR_PRODUCT";
