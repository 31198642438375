import React from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import baseURL from "../../Api/baseURL";
import shoppingCart from "../../images/shopping-cart.png";

// import ProductCardHook from "../../hooks/product/ProductCardHook";
import AddToCartHook from "../../hooks/cart/AddToCartHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const ProductCard = ({ item, fourImage }) => {
  // const [favImg, handleFav] = ProductCardHook(item, favProduct);
  const { t } = useTranslation();
  const currencytype = Cookies.get("i18next") == "ar" ? "ريال " : "SAR";
  const [handleAddToCart, onClickProduct, show, handleClose] = AddToCartHook(
    item.productId,
    item
  );

  return (
    <Col
      xs="6"
      sm="6"
      md="4"
      lg={fourImage === true ? "6" : "3"}
      className="d-flex"
    >
      <Card
        className="my-1"
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "8px",
          border: "none",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 2px 2px 0 rgba(151,151,151,0.5)",
        }}
      >
        {/*item.countInStock == 0 ? (
          <div
            style={{
              backgroundColor: "#9A0E2A",
              color: "while",

              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              position: "absolute",
              top: "35%",
              translate: "(-50% , 50%)",
              opacity: ".6",
            }}
          >
            <span className="text-uppercase px-4 py-1 text-white  text-center  ">
              {t("product not found in stock")}
            </span>
          </div>
        ) : (
          ""
        )*/}
        <div className="d-flex justify-content-between mx-1  py-1">
          {/*false ? (
            <div className="d-flex">
              <div className="card-rate mx-1">
                {item.ratingsAverage && item.ratingsAverage.toFixed(1)}
              </div>
            </div>
          ) : (
            <div></div>
          )*/}
        </div>

        <Card.Img
          onClick={() => onClickProduct(item.productId)}
          style={{
            height: "215px",
            width: "95%",
            objectFit: "contain",
            cursor: "pointer",
            alignSelf: "center",
            padding: "10px",
          }}
          src={baseURL.getUri() + item?.productImage}
        />
        <Card.Body>
          <Card.Title>
            <div
              onClick={() => onClickProduct(item.productId)}
              title={item.productName}
              className="card-title"
            >
              {item.productName}
            </div>
          </Card.Title>
          <Card.Text>
            <div className="d-flex justify-content-between ">
              <div className="d-flex">
                <div className="card-price" style={{ color: "#d53a59" }}>
                  <>
                    <div className="d-flex">
                      {item.productPrice}
                      <div className="card-currency mx-1">{t("SAR")}</div>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div md="12" className="d-flex justify-content-end mt-1">
              {/*
             <div md="12" className="mt-1 d-flex justify-content-center">
                {item.colors
                  ? item.colors.slice(0, 2).map((el, index) => (
                      <div
                        key={index}
                        onClick={() => colorClick(index, el)}
                        className="color ms-2"
                        style={{
                          backgroundColor: el,
                          border:
                            indexColor === index ? "2px solid black" : null,
                          width: "20px",
                          height: "20px",
                          boxShadow: "rgb(151 151 151) 0px 4px 4px 0px",
                        }}
                      ></div>
                    ))
                  : null}
              </div>
            */}

              <div
                onClick={handleAddToCart}
                className="product-cart-add-icon p-1 px-2  "
                style={{ backgroundColor: "#d53a59" }}
              >
                <i className="fa-solid fa-cart-arrow-down"></i>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      <ToastContainer />
    </Col>
  );
};

export default ProductCard;

// <span
//             className="text-uppercase position-absolute px-4 py-1 text-white  text-center  "
//             style={{
//               backgroundColor: "#000",
//               rotate: "-45deg",
//               right: "133px",
//               top: "53px",
//               borderRadius: "5px",
//             }}
//           ></span>
