import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hooks/useNotification";
import {
  getAllCartItems,
  removeAllCartItems,
  removeOneItem,
  updateItemCart,
} from "../../redux/actions/cartAction";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DeleteCartHook = (item) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [itemCount, setSetItemCount] = useState(item && item.productCount);

  const { t } = useTranslation();

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  // const handleDeletCart = async () => {
  //   setLoading(true);
  //   await dispatch(removeAllCartItems(user?._id));
  //   setLoading(false);
  // };
  const onChangeCount = (e) => {
    setSetItemCount(e.target.value);
  };

  // const res = useSelector((state) => state.cartReducer.deleteAllCartItems);

  // var user = "";
  // if (localStorage.getItem("user") != null) {
  //   user = JSON.parse(localStorage.getItem("user"));
  // }

  // useEffect(() => {
  //   if (loading === false) {
  //     if (res && res.success === true) {
  //       notify(t("All products have been removed"), "success");
  //       setTimeout(() => {
  //         window.location.reload(false);
  //       }, 1500);
  //     } else {
  //     }
  //   }
  // }, [loading]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleDeleteItem = async () => {
    await dispatch(removeOneItem(item.carId));
    setShow(false);

    window.location.reload(false);
  };
  const handleUpdateCart = async () => {
    await dispatch(updateItemCart(item.carId, itemCount));
    window.location.reload(false);
  };

  return [
    show,
    handleClose,
    handleShow,
    handleDeleteItem,
    itemCount,
    onChangeCount,
    handleUpdateCart,
  ];
};

export default DeleteCartHook;
