import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneUser,
  updateUserPassword,
  updateUserProfile,
} from "../../redux/actions/authActions";
import notify from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import avatar from "../../images/avatar.png";
import { useTranslation } from "react-i18next";
import baseURL from "../../Api/baseURL";

const ProfileHook = () => {
  const navigate = useNavigate();
  let user = [];
  if (localStorage.getItem("user") != null)
    user = JSON.parse(localStorage.getItem("user"));

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showTable, setShowTable] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(true);
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [confirmPasswordDeleteProfile, setConfirmPasswordDeleteProfile] =
    useState("");
  const [loadingPasswordProfile, setLoadingPasswordProfile] = useState(true);

  const [showDelete, setShowDelete] = useState(false);

  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getOneUser(user.userId));
  }, []);

  const userProfile = useSelector((state) => state.authReducers.oneUser);

  useEffect(() => {
    if (userProfile) {
      console.log("userProfile");
      console.log(userProfile);
      setName(userProfile.userName);
      setMobile(userProfile.userPhone);
      setEmail(userProfile.userEmail);
      setImage(
        userProfile.userImage !== ""
          ? baseURL.getUri() + userProfile.userImage
          : avatar
      );
    }
  }, [userProfile]);

  const onChangeConfirmPasswordDeleteProfile = (e) => {
    setConfirmPasswordDeleteProfile(e.target.value);
  };

  const onChangeName = (e) => {
    e.persist();
    setName(e.target.value);
  };

  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);

    console.log("selectedFile");
    console.log(selectedFile);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("UserName", name);
    formData.append("UserPhone", mobile);
    formData.append("UserEmail", email.toLocaleLowerCase());
    formData.append("userImage", selectedFile);
    await dispatch(updateUserProfile(user.userId, formData));
    setLoading(false);

    setShow(false);
  };

  const res = useSelector((state) => state.authReducers.userProfile);

  console.log("reswwwww");
  console.log(res);

  useEffect(() => {
    if (loading === false) {
      if (res.data === "تم نعديل المحتوي") {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      } else {
        notify("فشل فيه عملية التحديث", "warn");
      }
    }
  }, [loading]);
  // change user password
  const [oldpassword, setOldpassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingPass, setLoadingPass] = useState("");

  const onChangeOldPassword = (e) => {
    e.persist();
    setOldpassword(e.target.value);
  };
  const onChangeNewPassword = (e) => {
    e.persist();
    setNewPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };

  const changePassword = async () => {
    if (oldpassword === "" || newPassword === "" || confirmPassword === "") {
      notify("من فضلك اكمل البيانات", "error");
    } else if (newPassword !== confirmPassword) {
      notify("تاكيد البسورد غير صحيح", "error");
    } else {
      setLoadingPass(true);
      await dispatch(
        updateUserPassword(user._id, {
          oldPassword: oldpassword,
          password: newPassword,
        })
      );
      setLoadingPass(false);
    }
  };

  const resPassword = useSelector((state) => state.authReducers.userPassword);

  useEffect(() => {
    if (loadingPass === false) {
      if (resPassword && resPassword.success === true) {
        notify("تم تغير كلمة المرور بنجاح", "success");
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate(`/login`);
        }, 1500);
      } else {
        notify(t("The password is incorrect"), "warn");
      }
    }
  }, [loadingPass]);

  // const oneUser = useSelector((state) => state.usersReducers.oneUser);

  useEffect(() => {
    const get = async () => {
      // await dispatch(getOneUser(user._id));
    };
    get();
  }, []);

  const handleDelete = async () => {
    if (confirmPasswordDeleteProfile === "")
      return notify(
        t("Enter the password to confirm account deletion"),
        "error"
      );
    setLoadingPasswordProfile(true);
    await dispatch(
      updateUserPassword(user._id, {
        oldPassword: confirmPasswordDeleteProfile,
        password: confirmPasswordDeleteProfile,
        status: false,
      })
    );
    setLoadingPasswordProfile(false);
  };

  useEffect(() => {
    if (loadingPasswordProfile === false) {
      if (resPassword && resPassword.success === true) {
        notify("تم  حذف الحساب بنجاح", "success");
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/login");
        }, 1500);
      } else {
        notify(t("The password is incorrect"), "warn");
        setConfirmPasswordDeleteProfile("");
      }
    }
  }, [loadingPasswordProfile]);

  useEffect(() => {
    if (loadingSubmit === false) {
      if (res && res.success === true) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/profile");
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loadingSubmit]);

  return [
    user,
    handleShow,
    handleClose,
    handleSubmit,
    show,
    name,
    mobile,
    email,
    onChangeName,
    onChangeMobile,
    onChangeEmail,
    changePassword,
    oldpassword,
    newPassword,
    confirmPassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,
    showTable,

    showDelete,
    handleShowDelete,
    handleCloseDelete,
    handleDelete,
    confirmPasswordDeleteProfile,
    onChangeConfirmPasswordDeleteProfile,
    userProfile,
    image,
    onImageChange,
  ];
};

export default ProfileHook;

// setValuekeyInput(
//   [...valueKeyInput,
//     {value: value} ,
//   ]
// );
// setValuekeyInput(
//   ...valueKeyInput,
//   valueKeyInput[key] = value,
// );
