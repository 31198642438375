import { GET_ALL_AGENTS } from "../type";
const inital = {
  allAgents: [],
};

const agentReducers = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_AGENTS:
      return {
        ...state,
        allAgents: action.payload,
      };

    default:
      return state;
  }
};
export default agentReducers;
