import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SubTitle = ({ title, btntitle, path }) => {
  return (
    <div
      className="d-flex justify-content-between  px-3 p-3  border-black mt-3 mb-3 border-dark-subtle"
      style={{ borderRadius: "6px" }}
    >
      <div className="sub-tile" style={{ color: "#000", fontSize: "22px" }}>
        {title}
      </div>
      {btntitle ? (
        <Link to={`${path}`} style={{ textDecoration: "none" }}>
          <div
            className="shopping-now"
            style={{ color: "#d53a59", borderColor: "#d53a59" }}
          >
            {btntitle}
          </div>
        </Link>
      ) : null}
    </div>
  );
};

export default SubTitle;
