import {
  GET_ALL_NEW_PRODUCTS,
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCTS_CATEGORY,
  GET_ALL_PRODUCTS_COMPANY,
  GET_ERROR,
  GET_FOUR_IMAGE_PRODUCTS,
  GET_PRODUCT_DETAILS,
  SEARCH_FOR_PRODUCT,
} from "../type";
import { useGetData } from "../../database/useGetData";
import { useInsertData } from "../../database/useInsertData";

// export const getAllProductsWithPage =
//   (PageNumber, limit, categoryId, companyId) => async (dispatch) => {
//     try {
//       // Construct the base URL
//       let url = `/api/Product/Select/All/Product/${PageNumber}/${limit}`;

//       // Initialize an array to hold query parameters
//       const queryParams = [];

//       // Add query parameters if they are defined
//       if (categoryId) {
//         queryParams.push(`categoryId=${categoryId}`);
//       }
//       if (companyId) {
//         queryParams.push(`companyId=${companyId}`);
//       }

//       // If there are any query parameters, append them to the URL
//       if (queryParams.length > 0) {
//         url += `?${queryParams.join("&")}`;
//       }

//       const response = await useGetData(url);

//       dispatch({
//         type: GET_ALL_PRODUCT,
//         payload: response,
//         loading: true,
//       });
//     } catch (error) {
//       dispatch({
//         type: GET_ALL_PRODUCT,
//         payload: error,
//       });
//     }
//   };
export const getAllMorePaidProducts = () => async (dispatch) => {
  try {
    // Construct the base URL
    let url = `/api/Product/Select/Most/Products/Paied`;

    // // Initialize an array to hold query parameters
    // const queryParams = [];

    // // Add query parameters if they are defined
    // if (categoryId) {
    //   queryParams.push(`categoryId=${categoryId}`);
    // }
    // if (companyId) {
    //   queryParams.push(`companyId=${companyId}`);
    // }

    // // If there are any query parameters, append them to the URL
    // if (queryParams.length > 0) {
    //   url += `?${queryParams.join("&")}`;
    // }

    const response = await useGetData(url);

    dispatch({
      type: GET_ALL_PRODUCT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRODUCT,
      payload: error,
    });
  }
};

export const getAllNewProducts = () => async (dispatch) => {
  try {
    // Construct the base URL
    let url = `/api/Product/Select/Last/Updated/Product`;

    // // Initialize an array to hold query parameters
    // const queryParams = [];

    // // Add query parameters if they are defined
    // if (categoryId) {
    //   queryParams.push(`categoryId=${categoryId}`);
    // }
    // if (companyId) {
    //   queryParams.push(`companyId=${companyId}`);
    // }

    // // If there are any query parameters, append them to the URL
    // if (queryParams.length > 0) {
    //   url += `?${queryParams.join("&")}`;
    // }

    const response = await useGetData(url);

    dispatch({
      type: GET_ALL_NEW_PRODUCTS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_NEW_PRODUCTS,
      payload: error,
    });
  }
};

export const getOneProduct = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/Product/Select/Product/${id}`);

    dispatch({
      type: GET_PRODUCT_DETAILS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_DETAILS,
      payload: error,
    });
  }
};

// get product by category
export const getProductsByCategory =
  (categoryId, limit, page) => async (dispatch) => {
    try {
      const response = await useGetData(
        `/api/Product/Select/All/Product/${page}/${limit}?categoryId=${categoryId}`
      );

      dispatch({
        type: GET_ALL_PRODUCTS_CATEGORY,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PRODUCTS_CATEGORY,
        payload: error.response,
      });
    }
  };

// get product by company
export const getProductsByCompany =
  (companyId, limit, page) => async (dispatch) => {
    try {
      const response = await useGetData(
        `/api/Product/Select/All/Product/${page}/${limit}?companyId=${companyId}`
      );

      dispatch({
        type: GET_ALL_PRODUCTS_COMPANY,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PRODUCTS_COMPANY,
        payload: error.response,
      });
    }
  };

//get four image of products to show at home page
export const getFourImageProducts = () => async (dispatch) => {
  try {
    // Construct the base URL
    let url = `/api/Product/Select/Four/Image/Product`;

    const response = await useGetData(url);

    dispatch({
      type: GET_FOUR_IMAGE_PRODUCTS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_FOUR_IMAGE_PRODUCTS,
      payload: error,
    });
  }
};

//search for products

export const getAllProductsSearch = (body, limit, page) => async (dispatch) => {
  try {
    const response = await useInsertData(
      `/api/Product/Select/Product/By/Name/${page}/${limit}`,
      body
    );

    dispatch({
      type: SEARCH_FOR_PRODUCT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};
