import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getAllProductsSearch } from "../../redux/actions/productsAction";

const ViewSearchProductHooks = (searchWord) => {
  // console.log("searchWord");
  // console.log(searchWord);

  //const location = useLocation();
  //console.log(window.location.pathname);

  const [wordSearch, setWordSearch] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  // let [items, setItems] = useState([]);

  const location = useLocation();

  // console.log(searchWord)
  let limit = 8;
  const dispatch = useDispatch();

  const getProduct = async () => {
    setWordSearch(searchWord);
    let word = "";
    if (searchWord !== undefined && searchWord.trim !== "") word = wordSearch;
    setLoadingSpinner(true);
    console.log("word searchWord");
    console.log(searchWord);
    console.log(word);
    await dispatch(getAllProductsSearch({ productName: searchWord }, limit, 1));
    setLoadingSpinner(false);
  };

  useEffect(() => {
    getProduct();
    window.scrollTo(0, 0);
  }, []);

  const allproductsSearch = useSelector(
    (state) => state.allProduct.serachProducts
  );

  // console.log("allproductsSearch");
  // console.log(allproductsSearch);

  const onPress = async (page) => {
    await dispatch(
      getAllProductsSearch({ productName: searchWord }, limit, page)
    );
  };

  let items = [];
  let pagination = [];

  try {
    if (allproductsSearch)
      if (allproductsSearch.products) items = allproductsSearch.products;
      else items = [];
  } catch (error) {}
  try {
    if (allproductsSearch)
      if (allproductsSearch.pagging)
        pagination = allproductsSearch.pagging.pageCount;
      else pagination = [];
  } catch (error) {}

  return [items, pagination, onPress, loadingSpinner, getProduct];
};

export default ViewSearchProductHooks;
