import { Col, Row } from "react-bootstrap";
import baseURL from "../../Api/baseURL";
import { useTranslation } from "react-i18next";

const ProductsCartDetails = ({ item }) => {
  console.log("item");
  console.log(item);
  const { t } = useTranslation();

  return (
    <Col xs="12" className="cart-item-body my-2 d-flex px-2 mb-5 ">
      <img
        width="160px"
        height="250px"
        src={(item && baseURL.getUri() + item.productImage) || ""}
        alt=""
        style={{ objectFit: "contain" }}
      />
      <div className="w-100 mx-3 my-auto">
        <Row className="justify-content-between">
          <Col sm="12" className=" d-flex flex-row justify-content-between">
            <div className="d-inline pt-2 cat-text">
              {item && item?.productName}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col sm="12" className=" d-flex flex-row justify-content-start">
            <div className="d-inline pt-2 cat-title">
              {item && item?.productDescripition}
            </div>
          </Col>
        </Row>

        <Row className="justify-content-between">
          <Col
            sm="12"
            className=" d-flex flex-row justify-content-between flex-column  justify-content-end"
          >
            <div className="d-inline pt-2 barnd-text">
              {(item && item?.productPrice) || ""} {t("SAR")}
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default ProductsCartDetails;
