import {
  CREATE_NEW_USER,
  LOGIN_USER,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  RESET_PASSWORD,
  GET_ONE_USER,
} from "../type";
import {
  useInsertData,
  useInsertDataWithImage,
} from "../../database/useInsertData";
import {
  useUpdateData,
  useUpdateDataWithImage,
} from "../../database/useUpdateData";
import { useGetData } from "../../database/useGetData";

export const createNewUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(
      `/api/User/Insert/User`,
      data
    );

    dispatch({
      type: CREATE_NEW_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_USER,
      payload: error.response,
    });
  }
};

export const loginUser = (data) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/User/User/Login`, data);

    dispatch({
      type: LOGIN_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_USER,
      payload: error.response,
    });
  }
};

export const updateUserProfile = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/User/Update/User/${id}`,
      body
    );

    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: error.response,
    });
  }
};

export const updateUserPassword = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/users/${id}`, body);

    dispatch({
      type: UPDATE_USER_PASSWORD,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PASSWORD,
      payload: error.response,
    });
  }
};

// reset password
export const resetPassword = (val, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/forgetPassword/${val}`, body);
    dispatch({
      type: RESET_PASSWORD,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD,
      payload: error.response,
    });
  }
};

//get one user
export const getOneUser = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/User/Select/User/By/${id}`);

    dispatch({
      type: GET_ONE_USER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_USER,
      payload: error,
    });
  }
};
