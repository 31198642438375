import { GET_ALL_ORDERS_BY_USER, GET_ONE_ORDER, PAY_INVOICE } from "../type";

import { useGetData } from "../../database/useGetData";
import { useInsertData } from "../../database/useInsertData";

export const getAllOrderByUser = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/User/Select/User/Invoice/${id}`);

    dispatch({
      type: GET_ALL_ORDERS_BY_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ORDERS_BY_USER,
      payload: error,
    });
  }
};

export const getOneOrder = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/User/Select/Invoice/Product/${id}`);

    dispatch({
      type: GET_ONE_ORDER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_ORDER,
      payload: error,
    });
  }
};

export const payInvoiceOrder = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/User/Pay/TheInvoice`, body);
    dispatch({
      type: PAY_INVOICE,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: PAY_INVOICE,
      payload: error,
    });
  }
};
