import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import baseURL from "../../Api/baseURL";

const DiscountSection = ({
  image,
  title,
  company,
  phone,
  color,
  secondaryColor,
}) => {
  return (
    <Container>
      <Row
        className="discount-backcolor my-2  mx-2  d-flex w-100 justify-content-center  align-items-center "
        style={{
          background: `radial-gradient(circle, ${color} 0%, ${secondaryColor} 100%, ${secondaryColor}  100%)`,
        }}
      >
        <Col className="d-flex justify-content-between align-content-center">
          {" "}
          <div className="discount-title">{title}</div>
        </Col>
        <Col className="d-flex justify-content-between align-content-center">
          <div className="discount-title">{company}</div>
        </Col>
        <Col className="d-flex justify-content-between align-content-center">
          <div className="discount-title">{phone}</div>
        </Col>

        <Col className="d-flex justify-content-between align-content-center p-1">
          {" "}
          <img className="dicount-img" src={baseURL.getUri() + image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default DiscountSection;
