import baseURL from "../Api/baseURL";

const useGetData = async (url, params) => {
  const res = await baseURL.get(url, params);
  return res.data;
};

const useGetDataToken = async (url, params) => {
  const username = "11178383"; // Replace with your username
  const password = "60-dayfreetrial"; // Replace with your password

  const base64credentials = Buffer.from(`${username}:${password}`).toString(
    "base64"
  );

  const config = {
    headers: {
      Authorization: `Basic ${base64credentials}`,
    },
    withCredentials: true,
  };
  try {
    const res = await baseURL.get(url, config);
    return res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};

export { useGetData, useGetDataToken };
