import { GET_ALL_AGENTS } from "../type";
import { useGetData } from "../../database/useGetData";

export const getAllAgents = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/Agent/Select/All/Agent`);

    dispatch({
      type: GET_ALL_AGENTS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_AGENTS,
      payload: error,
    });
  }
};
