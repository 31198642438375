import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ViewProductDetails from "../../hooks/product/ViewProductDetailsHooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CardProductsContainer from "../../components/Products/CardProductsContainer";
import ProductDetails from "../../components/Products/ProductDetails";

const ProductDetailsPage = () => {
  const { id } = useParams();
  const [productItem, similarProducts] = ViewProductDetails(id);

  // console.log("similarProducts");
  // console.log(similarProducts);

  const { t } = useTranslation();

  // if (item) {
  //   var rateAv = item.ratingsAverage;
  //   var reateQty = item.ratingsQuantity;
  // }

  return (
    <div style={{ minHeight: "670px" }}>
      {/* <Helmet>
        <meta name="description" content={productItem.descriptionProduct} />
        <meta name="keywords" content={productItem.keywordsProducts} />
      </Helmet> */}

      {/* {settingsData?.data && settingsData?.data?.showNavHeader === true ? (
        <CategoryHeader />
      ) : null} */}

      <Container>
        <ProductDetails />
        {/* <RateContainer rateAv={rateAv} reateQty={reateQty} /> */}
        <CardProductsContainer
          products={similarProducts?.products}
          title={t("Products you may like")}
        />
      </Container>
    </div>
  );
};

export default ProductDetailsPage;
