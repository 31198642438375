import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import { getData } from "../../redux/actions/termsPolicyAboutAction";

const TermsPolicyAboutHook = (type) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const res = useSelector((state) => state.termsPolicyAboutReducer.data);

  useEffect(() => {
    const get = async () => {
      window.scrollTo(0, 0);
      setLoading(true);
      await dispatch(getData(type));
      setLoading(false);
    };
    get();
  }, [dispatch, type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type]);

  console.log("res");
  console.log(res);

  return [res];
};

export default TermsPolicyAboutHook;
