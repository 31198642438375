import React, { useState } from "react";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import ProfileHook from "../../hooks/user/ProfileHook";
import editicon from "../../images/edit.png";
import deleteicon from "../../images/delete.png";

import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Avatar } from "@mui/material";

const UserProfile = () => {
  const { t } = useTranslation();

  const [
    user,
    handleShow,
    handleClose,
    handleSubmit,
    show,
    name,
    mobile,
    email,
    onChangeName,
    onChangeMobile,
    onChangeEmail,
    changePassword,
    oldpassword,
    newPassword,
    confirmPassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,
    showTable,

    showDelete,
    handleShowDelete,
    handleCloseDelete,
    handleDelete,
    confirmPasswordDeleteProfile,
    onChangeConfirmPasswordDeleteProfile,
    userProfile,
    image,
    onImageChange,
  ] = ProfileHook();
  return (
    <div>
      {/* edit profole */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Modification of personal data")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <input
              value={name}
              onChange={onChangeName}
              type="text"
              className="input-form d-block mt-3 px-3"
              placeholder={t("name")}
            />

            <input
              value={mobile}
              onChange={onChangeMobile}
              type="text"
              className="input-form d-block  mt-3 px-3"
              placeholder={t("phone")}
            />
            <input
              value={email}
              onChange={onChangeEmail}
              type="text"
              className="input-form d-block  mt-3 px-3"
              placeholder={t("email")}
            />

            {/* <img src={avatar} alt="" height="100px" width="120px" /> */}
            <div>
              <label htmlFor="upload-photo">
                <img
                  id="image"
                  src={image}
                  alt="fzx"
                  height="100px"
                  width="120px"
                  style={{ cursor: "pointer", marginTop: "12px" }}
                />
              </label>
              <input
                type="file"
                name="photo"
                onChange={onImageChange}
                id="upload-photo"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            style={{ color: "#000" }}
            onClick={handleClose}
          >
            {t("retreat")}
          </Button>
          <Button
            className="font"
            style={{ color: "#000" }}
            variant="success"
            onClick={handleSubmit}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete profile */}
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            {" "}
            <input
              value={confirmPasswordDeleteProfile}
              onChange={onChangeConfirmPasswordDeleteProfile}
              placeholder={t("confirm password")}
              type="password"
              className="user-input my-2 text-center mx-auto w-100"
            />{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={handleCloseDelete}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="admin-content-text">{t("Profile")}</div>
      <div className="user-address-card-2 my-3 px-2">
        <Row className="d-flex justify-content-between pt-2">
          <Col xs="6" className="d-flex">
            <div className="p-2">{t("name")}</div>
            <div className="p-1 item-delete-edit">{`${
              userProfile.userName || ""
            } `}</div>
          </Col>
          <Col xs="6" className="d-flex justify-content-end">
            <div className="d-flex mx-2" onClick={handleShow}>
              <img
                alt=""
                className="ms-1 mt-2"
                src={editicon}
                height="17px"
                width="15px"
              />
              <p className="item-delete-edit"> {t("Modify")}</p>
            </div>
            {/* <div className="d-flex " onClick={handleShowDelete}>
              <img
                alt=""
                className="ms-1 mt-2"
                src={deleteicon}
                height="17px"
                width="15px"
              />
              <p className="item-delete-edit"> {t("move")}</p>
            </div> */}
          </Col>
        </Row>

        <Row className="">
          <Col xs="12" className="d-flex">
            <div className="p-2">{t("phone")}:</div>
            <div className="p-1 item-delete-edit">
              {userProfile.userPhone || ""}
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col xs="12" className="d-flex">
            <div className="p-2">{t("email")}:</div>
            <div className="p-1 item-delete-edit">
              {userProfile.userEmail || ""}
            </div>
          </Col>
        </Row>
        <Row
          className="mt-5 user-address-card-3 p-5"
          style={{
            position: "sticky",
          }}
        >
          <Col xs="10" sm="8" md="6" className="">
            <div className="admin-content-text mt-2">
              {t("change password")}
            </div>
            <label htmlFor="oldPassword" className="d-block mt-4 ">
              {t("old password")}
            </label>
            <input
              id="oldPassword"
              value={oldpassword}
              onChange={onChangeOldPassword}
              type="password"
              className="input-form d-block mt-1 px-3"
              placeholder={t("enter old password")}
            />
            <label htmlFor="newPassword" className="d-block mt-2 ">
              {t("new password")}
            </label>
            <input
              id="newPassword"
              value={newPassword}
              onChange={onChangeNewPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("enter new password")}
            />
            <label htmlFor="verifyPassword" className="d-block mt-2 ">
              {t("confirm password")}
            </label>
            <input
              id="verifyPassword"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("confirm new password")}
            />
            <button
              onClick={changePassword}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: "#d53a59" }}
            >
              {t("save")}
            </button>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserProfile;
