import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsByCompany } from "../../redux/actions/productsAction";

const ViewAllProductsByCompanyHook = (catId) => {
  let limit = 8;
  const dispatch = useDispatch();

  const [loadingSpinner, setLoadingSpinner] = useState(true);

  const getProduct = async () => {
    await dispatch(getProductsByCompany(catId, limit, 1));
  };

  useEffect(() => {
    setLoadingSpinner(true);
    getProduct();
    setLoadingSpinner(false);
    window.scrollTo(0, 0);
  }, []);

  const allproduct = useSelector((state) => state.allProduct.productsByCompany);

  const onPress = async (page) => {
    await dispatch(getProductsByCompany(catId, limit, page));
  };

  let items = [];
  let pagination = [];

  try {
    if (allproduct)
      if (allproduct.products) items = allproduct.products;
      else items = [];
  } catch (error) {}
  try {
    if (allproduct)
      if (allproduct.pagging) pagination = allproduct.pagging.pageCount;
      else pagination = [];
  } catch (error) {}

  return [items, pagination, onPress, loadingSpinner];
};

export default ViewAllProductsByCompanyHook;
