import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import { useParams } from "react-router-dom";
import baseURL from "../../Api/baseURL";
import OrderDetailHook from "../../hooks/user/OrderDetailHook";
import CartItem from "../Cart/CartItem";
import { useTranslation } from "react-i18next";
import ProductsCartDetails from "./ProductsCartDetails";

const OrderDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [orderData, loading] = OrderDetailHook(id);

  return (
    <div>
      <Row className="d-flex justify-content-center">
        <Col xs="12" md="9">
          {orderData?.length >= 1
            ? orderData?.map((item) => {
                return <ProductsCartDetails key={item.carId} item={item} />;
              })
            : ""}
        </Col>
        {/* <Col xs="6" md="3">
          <CartCheckout cartItems={cartItems} totalPrice={totalPrice} />
        </Col> */}
      </Row>
    </div>
  );
};

export default OrderDetails;
