import { GET_ALL_CATEGORIES, GET_ALL_COMPANIES } from "../type";
const inital = {
  categories: [],
  companies: [],

  loading: true,
};

const categoryReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case GET_ALL_COMPANIES:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default categoryReducer;
