import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllCartItems } from "../../redux/actions/cartAction";

const UserCartHook = () => {
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [itemNumber, setItemsNumber] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (user !== "") {
      const get = async () => {
        setLoading(true);
        // console.log("user");
        // console.log(user);
        await dispatch(getAllCartItems(user.userId));
        setLoading(false);
      };
      get();
    }
  }, []);
  const res = useSelector((state) => state.cartReducer.userCartItems);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        // console.log("ressss Cart");
        // console.log(res);

        setItemsNumber(res.cartTotalNumberOfProduct);
        setCartItems(res.cartProduct);

        setTotalPrice(res?.cartTotalPrice || 0);
      } else {
        setItemsNumber(0);
        setCartItems([]);
      }
    }
  }, [loading, res]);

  return [res, cartItems, totalPrice, itemNumber];
};

export default UserCartHook;
