import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanies } from "../../redux/actions/categoryAction";
import { useLocation } from "react-router-dom";

const CompanyPageHook = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  useEffect(() => {
    if (location.pathname === "/allcategory") {
      setLoadingSpinner(true);
      dispatch(getAllCompanies());
      setLoadingSpinner(false);
    } else {
      setLoadingSpinner(true);
      dispatch(getAllCompanies());
      setLoadingSpinner(false);
    }

    window.scrollTo(0, 0);
  }, [dispatch, location.pathname]);
  const companies = useSelector((state) => state.allCategory.companies);
  const loading = useSelector((state) => state.allCategory.loading);

  const colors = [
    "#FFD3E8",
    "#F4DBA5",
    "#55CFDF",
    "#FF6262",
    "#0034FF",
    "#FFD3E8",
  ];

  return [companies, loading, loadingSpinner, colors];
};

export default CompanyPageHook;
