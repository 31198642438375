import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import CategoryHeader from "../../components/Category/CategoryHeader";
import CardProductsContainer from "../../components/Products/CardProductsContainer";
import LoadingSpinner from "../../components/utilities/LoadingSpinner";
import Pagination from "../../components/utilities/Pagination";
// import ProductsResults from "../../components/utilities/ProductsResults";
// import SideFilter from "../../Components/Utilities/SideFilter";
import ViewSearchProductHooks from "../../hooks/product/ViewSearchProductHooks";
import NavBarSearchHook from "../../hooks/search/NavBarSearchHook";

const ShopProductPage = () => {
  const [items, pagination, onPress, loadingSpinner] =
    ViewSearchProductHooks("");

  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  return (
    <div>
      <Container>
        {/* <ProductsResults onClick={getProduct} title={`${result} نتيجة بحث`} /> */}
        <Row className="d-flex">
          {/* <Col className="d-flex" md="2" sm="2">
            <SideFilter
              props={{
                selectedCategorySearch,
                selectedBrandSearch,
                search,
                onChangeSetMinPrice,
                onChangeSetMaxPrice,
              }}
            />
          </Col> */}
          <Col md="10" sm="10" xs="12">
            {loadingSpinner === true ? (
              <LoadingSpinner />
            ) : (
              <CardProductsContainer products={items} title="" btntitle="" />
            )}{" "}
          </Col>
        </Row>
        <Pagination pageCount={pageCount} onPress={onPress} />
      </Container>
    </div>
  );
};

export default ShopProductPage;
