import React, { useEffect } from "react";

const NotFoundPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div
              className="col-sm-10 col-sm-offset-1 text-center"
              style={{ width: "100%" }}
            >
              <div className="four_zero_four_bg">
                <h1 className="text-center" style={{ color: "#d53a59" }}>
                  404
                </h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2" style={{ color: "#d53a59" }}>
                  Look like you're lost
                </h3>

                <p style={{ color: "#d53a59" }}>
                  the page you are looking for not avaible!
                </p>

                <a
                  href="/"
                  className="link_404"
                  style={{ backgroundColor: "#d53a59" }}
                >
                  Go to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
