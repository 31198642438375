import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const UserMapLocation = ({ latitude, longitude, onPress }) => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setLocation({ latitude, longitude });
    setError(null);
  };

  const handleError = (error) => {
    setError(error.message);
    setLocation(null);
  };
  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    }
  };
  requestLocation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCezn-8NoOfJ0kDliIBjWY7UN7L8ZBuIBg",
  });

  const center = useMemo(() => ({ lat: +latitude, lng: +longitude }));
  return (
    <div className="d-flex flex-column ">
      {!isLoaded ? (
        <div>is Loading</div>
      ) : (
        <GoogleMap
          zoom={15}
          center={center}
          mapContainerClassName="mapContainer"
          onClick={(e) => onPress(e.latLng.lat(), e.latLng.lng())}
        >
          <Marker position={center} />
        </GoogleMap>
      )}
    </div>
  );
};

export default UserMapLocation;
