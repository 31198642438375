import { GET_POLICY_TERMS_ABOUT } from "../type";
const inital = {
  data: [],
};

const termsPolicyAboutReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_POLICY_TERMS_ABOUT:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default termsPolicyAboutReducer;
