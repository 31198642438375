import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hooks/useNotification";
import { loginUser } from "../../redux/actions/authActions";
import { useTranslation } from "react-i18next";

const LoginHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  const { t } = useTranslation();

  const onChangeVal = (e) => {
    setVal(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = async () => {
    if (val === "") {
      notify("تاكد من البريد الإلكتروني", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل كلمة المرور", "error");
      return;
    } else {
      setIsPress(true);
      setLoading(true);
      await dispatch(
        loginUser({
          userEmail: val.toLocaleLowerCase(),
          userPassword: password,
        })
      );
      setLoading(false);
      setIsPress(false);
    }
  };
  const res = useSelector((state) => state.authReducers.loginUser);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.userId) {
          console.log("dddddddddddddddddddddddddddddddd");
          // localStorage.setItem("token", res.token);
          localStorage.setItem("user", JSON.stringify(res));
          notify("تم تسجيل الدخول بنجاح", "success");
          setTimeout(() => {
            window.location.href = `/`;
          }, 1500);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("page");
        }

        if (res) {
          if (res === "الايميل او الباسورد خطأ") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            notify("خطأ في البريد الالكتروني أو كلمة المرور   ", "error");
          }
          // if (res.data === "The user is blocked") {
          //   localStorage.removeItem("token");
          //   localStorage.removeItem("user");
          //   notify("هذا المستخدم محظور من قبل الادارة", "error");
          // }
        }

        setLoading(true);
      }
    }
  }, [loading]);

  return [
    val,
    password,
    onChangeVal,
    onChangePassword,
    loading,
    onSubmit,
    isPress,
  ];
};

export default LoginHook;
