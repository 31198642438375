import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CartCheckout from "../../components/Cart/CartCheckout";
import CartItem from "../../components/Cart/CartItem";
import UserCartHook from "../../hooks/cart/UserCartHook";

const CartPage = () => {
  const [res, cartItems, totalPrice] = UserCartHook();

  return (
    <Container style={{ minHeight: "670px" }}>
      <Row>
        <div className="cart-title mt-4">عربة التسوق</div>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="12" md="9">
          {cartItems?.length >= 1 ? (
            cartItems.map((item) => {
              return <CartItem key={item.carId} item={item} />;
            })
          ) : (
            <h6>لا يوجد منتجات في العربة</h6>
          )}
        </Col>
        <Col xs="6" md="3">
          <CartCheckout cartItems={cartItems} totalPrice={totalPrice} />
        </Col>
      </Row>
    </Container>
  );
};

export default CartPage;
