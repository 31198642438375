import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getOneOrder } from "../../redux/actions/orderAction";
import notify from "../useNotification";

const OrderDetailHook = (id) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [orderData, setOrderData] = useState([]);

  const get = async () => {
    if (id) {
      console.log("idddddddddddddd");
      console.log(id);
      setLoading(true);
      await dispatch(getOneOrder(id));
      setLoading(false);
    }
  };
  useEffect(() => {
    get();
  }, []);

  const res = useSelector((state) => state.orderReducer.oneOrder);

  useEffect(() => {
    if (loading === false) {
      if (res && res !== "لا توجد اي منتجات في الفتورة") {
        console.log("res orders");
        console.log(res);
        setOrderData(res);
      }
    }
  }, [loading]);

  return [orderData, loading];
};

export default OrderDetailHook;
