import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMorePaidProducts,
  getAllNewProducts,
  getFourImageProducts,
} from "../../redux/actions/productsAction";
import { getAllAgents } from "../../redux/actions/agentAction";

const ViewHomeProductsHooks = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [loading, setLoading] = useState(false);
  const [items, setitems] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [agents, setAgents] = useState([]);
  const [fourProducts, setFourProducts] = useState([]);

  useEffect(() => {
    const get = async () => {
      // ref.current.continuousStart();
      setLoading(true);
      console.log("loading");
      console.log(loading);
      await dispatch(getAllMorePaidProducts());
      await dispatch(getAllNewProducts());
      await dispatch(getAllAgents());
      await dispatch(getFourImageProducts());
      setLoading(false);
      // ref.current.complete();
    };
    get();
  }, []);

  const morePaidProducts = useSelector(
    (state) => state.allProduct.morePaidProducts
  );

  const newProductsResponse = useSelector(
    (state) => state.allProduct.newProducts
  );

  const agentsResponse = useSelector((state) => state.agentReducers.allAgents);

  const fourImageProductsResponse = useSelector(
    (state) => state.allProduct.fourImageProducts
  );

  useEffect(() => {
    if (loading === false) {
      setitems(morePaidProducts);
      setNewProducts(newProductsResponse);
      if (agentsResponse !== "لا يوجد وكلاء") {
        setAgents(agentsResponse);
      }
      setFourProducts(fourImageProductsResponse);
    }
  }, [
    loading,
    morePaidProducts,
    newProductsResponse,
    agentsResponse,
    fourImageProductsResponse,
  ]);

  return [items, newProducts, agents, fourProducts, ref];
};

export default ViewHomeProductsHooks;
