import { GET_ALL_ORDERS_BY_USER, GET_ONE_ORDER, PAY_INVOICE } from "../type";
const inital = {
  ordersByUser: [],
  oneOrder: [],
  payInvoice: [],
};

const orderReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS_BY_USER:
      return {
        ...state,
        ordersByUser: action.payload,
      };

    case GET_ONE_ORDER:
      return {
        ...state,
        oneOrder: action.payload,
      };
    case PAY_INVOICE:
      return {
        ...state,
        payInvoice: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
