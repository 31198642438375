import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import shoppingCart from "../../images/shopping-cart.png";
import user from "../../images/user.png";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import NavBarSearchHook from "../../hooks/search/NavBarSearchHook";
import UserCartHook from "../../hooks/cart/UserCartHook";
import ReactGA from "react-ga";
import avatar from "../../images/avatar.png";

import { BsQuestionCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/1logo.jpeg";
import baseURL from "../../Api/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { getOneUser } from "../../redux/actions/authActions";

const NavBarLogin = () => {
  const [onChangeSearch, searchWord] = NavBarSearchHook();

  const location = useLocation();

  const languages = [
    {
      code: "en",
      name: "English",
      dir: "ltr",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
    },
  ];
  const [t, i18n] = useTranslation();

  // console.log('cookies.get("i18next")');
  // console.log(cookies.get("i18next"));

  const currentLanguageCode = cookies.get("i18next") || "ar";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  const handleGoogleEvent = () => {
    ReactGA.event({
      category: "cart",
      action: "cart_details_action",
      label: "cart_label",
    });
  };

  useEffect(() => {
    // var titleAr = settingsRes?.data?.titleAr
    // document.title = titleAr ? titleAr : ''
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);

  // let word = "";
  // if (localStorage.getItem("searchWord") != null) {
  //   word = localStorage.getItem("searchWord");
  // }
  const [userData, setUserData] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      setUserData(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  useEffect(() => {
    if (userData !== "") {
      dispatch(getOneUser(userData.userId));
    }
  }, [dispatch, userData]);

  const userProfile = useSelector((state) => state.authReducers.oneUser);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("page");
    localStorage.removeItem("token");

    setUserData("");
  };
  const [res, cartItems, totalPrice, itemNumber] = UserCartHook();

  // console.log("itemNumber");
  // console.log(itemNumber);
  // console.log(useSelector((state) => state.cartReducer.userCartItems));
  const navigate = useNavigate();

  return (
    <Navbar
      className="sticky-top "
      expand="sm"
      style={{ backgroundColor: "#d53a59" }}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img alt="Logo" src={logo} className="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <FormControl
            onChange={onChangeSearch}
            value={searchWord}
            type="search"
            placeholder={t("search placeholder")}
            className="me-2 w-100"
            aria-label="Search"
          />
          <Nav className="me-auto">
            {i18n.language === "en" ? (
              <Nav.Link
                onClick={() => i18n.changeLanguage("ar")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                عربي
              </Nav.Link>
            ) : (
              <Nav.Link
                onClick={() => i18n.changeLanguage("en")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                English
              </Nav.Link>
            )}
            {userData != "" ? (
              <NavDropdown
                title={
                  <img
                    src={
                      userProfile.userImage !== ""
                        ? baseURL.getUri() + userProfile.userImage
                        : avatar
                    } // URL of the user's avatar
                    alt={userData.userName}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      textAlign: "center",
                    }}
                  />
                }
                id="basic-nav-dropdown"
                menuVariant="dark"
              >
                <div
                  style={{
                    backgroundColor: `#d53a59`,
                  }}
                >
                  <Fragment>
                    <NavDropdown.Item
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                      onClick={() => navigate(`/user/profile`)}
                    >
                      <Link
                        to={`/user/profile`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        {t("Profile")}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                      onClick={() => navigate(`/user/allorders`)}
                    >
                      <Link
                        to={`/user/allorders`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        {t("orders")}
                      </Link>
                    </NavDropdown.Item>

                    {/* <NavDropdown.Item
                      onClick={() => navigate(`/user/allorders`)}
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Link
                        to={`/user/allorders`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        {t("orders")}
                      </Link>
                    </NavDropdown.Item> */}

                    {/* <NavDropdown.Item
                      onClick={() => navigate(`/user/addresses`)}
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Link
                        to={`/user/addresses`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        {t("personal addresses")}
                      </Link>
                    </NavDropdown.Item> */}
                  </Fragment>

                  <NavDropdown.Item>
                    <Link
                      onClick={logOut}
                      to={`/`}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      {t("logout")}
                    </Link>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
            ) : (
              <NavLink
                to={`/login`}
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "transparent" : null,
                  textDecoration: isActive ? "none" : "none",
                  borderRight: isActive ? "none" : null,
                  marginBottom: isActive ? "10px" : "10px",
                })}
                className="nav-text d-flex mt-3 mx-1 justify-content-center"
              >
                <p style={{ color: "black", marginTop: "7px" }}>{t("login")}</p>
                <img
                  style={{ marginTop: "6px" }}
                  src={user}
                  className="login-img"
                  alt="sfvs"
                />
              </NavLink>
            )}

            {userData !== "" && (
              <NavLink
                to={`/cart`}
                className="nav-text d-flex mt-4 justify-content-center position-relative"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "transparent" : null,
                  textDecoration: isActive ? "none" : "none",
                  borderRight: isActive ? "none" : null,
                  marginBottom: isActive ? "10px" : "10px",
                  color: isActive ? "black" : "black",
                })}
                onClick={handleGoogleEvent}
              >
                <p className="p-1" style={{ color: "black" }}>
                  {t("cart")}
                </p>
                <img src={shoppingCart} className="login-img" alt="sfvs" />

                <span className="position-absolute  start-0 top-0 translate-middle badge rounded-pill bg-danger">
                  {itemNumber || 0}
                </span>
              </NavLink>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarLogin;
