import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllOrderByUser } from "../../redux/actions/orderAction";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const UserGetOrdersHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [orderData, setOrderData] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [status] = useState(searchParams.get("status"));
  const [message] = useState(searchParams.get("message"));

  console.log("status");
  console.log(status);
  console.log(message);
  const navigate = useNavigate();

  const { t } = useTranslation();
  useEffect(() => {
    if (status === "paid" && message === "APPROVED") {
      notify(t("Payment completed successfully"), "success");
      // Remove search parameters
      setTimeout(() => {
        searchParams.delete("status");
        searchParams.delete("message");
        setSearchParams(searchParams);
        navigate(window.location.pathname, { replace: true });
      }, 1000);
    }
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  let username = "";
  if (user != null) {
    username = user.userName;
  }
  const get = async () => {
    setLoading(true);
    await dispatch(getAllOrderByUser(user.userId));
    setLoading(false);
  };
  useEffect(() => {
    get();
  }, []);

  const res = useSelector((state) => state.orderReducer.ordersByUser);

  useEffect(() => {
    if (loading === false) {
      if (res && res !== "لا توجد اي طلبات") {
        console.log("res orders");
        console.log(res);
        setOrderData(res);
      }
    }
  }, [loading]);

  return [orderData, loading];
};

export default UserGetOrdersHook;
