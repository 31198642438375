import { GET_ALL_SLIDER } from "../type";
import { useGetData } from "../../database/useGetData";

export const getAllSlider = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/AboutUs/Select/All/Sliders`);

    dispatch({
      type: GET_ALL_SLIDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SLIDER,
      payload: error,
    });
  }
};
