import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import ProductDescription from "./ProductDescription";
import ViewProductDetails from "../../hooks/product/ViewProductDetailsHooks";
import { useParams } from "react-router-dom";
import baseURL from "../../Api/baseURL";

const ProductDetails = () => {
  const { id } = useParams();
  const [productItem, similarProducts] = ViewProductDetails(id);

  return (
    <div>
      <Row className="py-3">
        <Col lg="4">
          {productItem && (
            <Card.Img
              style={{
                height: "250px",
                width: "95%",
                objectFit: "contain",
                cursor: "pointer",
                alignSelf: "center",
              }}
              src={baseURL.getUri() + productItem?.productImage}
            />
          )}
        </Col>
        <Col lg="8">
          <ProductDescription />
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetails;
