import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const UserSideBar = () => {
  const { t } = useTranslation();

  const location = useLocation();
  console.log(location.pathname);

  const [hover1, setHover1] = useState(false);
  const handleMouseEnter1 = () => {
    setHover1(true);
  };
  const handleMouseLeave1 = () => {
    setHover1(false);
  };

  const [hover4, setHover4] = useState(false);
  const handleMouseEnter4 = () => {
    setHover4(true);
  };
  const handleMouseLeave4 = () => {
    setHover4(false);
  };

  return (
    <div className="sidebar">
      <div className="d-flex flex-column">
        <Link to={`/user/profile`} style={{ textDecoration: "none" }}>
          <div
            className="admin-side-text my-1 border-bottom p-2 mx-auto text-center"
            style={{
              backgroundColor:
                location.pathname === "/user/profile" ? "#d53a59" : "",
            }}
            onMouseEnter={handleMouseEnter4}
            onMouseLeave={handleMouseLeave4}
          >
            {t("Profile")}
          </div>
        </Link>

        <Link to={`/user/allorders`} style={{ textDecoration: "none" }}>
          <div
            className="admin-side-text mt-1 border-bottom p-2 mx-auto text-center"
            style={{
              backgroundColor:
                location.pathname === "/user/allorders" ? "#d53a59" : "",
            }}
            onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
          >
            {t("Application Management")}
          </div>
        </Link>

        {/* <Link to={`/user/addresses`} style={{ textDecoration: "none" }}>
          <div
            className="admin-side-text my-1 border-bottom p-2 mx-auto text-center"
            style={{
              backgroundColor: hover3 ? "#d53a59" : "",
            }}
            onMouseEnter={handleMouseEnter3}
            onMouseLeave={handleMouseLeave3}
          >
            {t("Personal Addresses")}
          </div>
        </Link> */}
      </div>
    </div>
  );
};
export default UserSideBar;
