import React, { useEffect, useState } from "react";
import { Carousel, Spinner } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { getAllSlider } from "../../redux/actions/sliderActions";
import baseURL from "../../Api/baseURL";

const Slider = () => {
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(getAllSlider());
    setLoading(false);
  }, []);

  const sliders = useSelector((state) => state.sliderReducer?.allSlider);
  // console.log("sliders");
  // console.log(sliders);
  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {loading === false ? (
        sliders &&
        sliders.message !== "Network Error" &&
        sliders.map((el) => {
          return (
            <Carousel.Item
              key={el.sliderId}
              className="slider-background sliderr"
              style={{
                background: `#fff`,
                borderRight: "unset",
              }}
              interval={2000}
            >
              <div
                className="d-flex flex-row justify-content-center align-items-center  "
                style={{ height: "100%" }}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  {el.sliderImage && (
                    <img
                      className="imgg"
                      src={baseURL.getUri() + el.sliderImage}
                      alt=" slide"
                    />
                  )}
                </div>
              </div>
            </Carousel.Item>
          );
        })
      ) : (
        <Spinner animation="border" variant="primary" />
      )}
    </Carousel>
  );
};

export default Slider;
