import {
  GET_ALL_NEW_PRODUCTS,
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCTS_CATEGORY,
  GET_ALL_PRODUCTS_COMPANY,
  GET_FOUR_IMAGE_PRODUCTS,
  GET_PRODUCT_DETAILS,
  SEARCH_FOR_PRODUCT,
} from "../type";
const inital = {
  morePaidProducts: [],
  newProducts: [],
  oneProduct: [],
  productsByCategory: [],
  productsByCompany: [],
  fourImageProducts: [],
  serachProducts: [],

  loading: true,
};

const productReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT:
      return {
        ...state,
        morePaidProducts: action.payload,
        loading: false,
      };
    case GET_ALL_NEW_PRODUCTS:
      return {
        ...state,
        newProducts: action.payload,
        loading: false,
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        oneProduct: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCTS_CATEGORY:
      return {
        ...state,
        productsByCategory: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCTS_COMPANY:
      return {
        ...state,
        productsByCompany: action.payload,
        loading: false,
      };
    case GET_FOUR_IMAGE_PRODUCTS:
      return {
        ...state,
        fourImageProducts: action.payload,
        loading: false,
      };
    case SEARCH_FOR_PRODUCT:
      return {
        ...state,
        serachProducts: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default productReducer;
