import React, { useMemo, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Row, Col } from "react-bootstrap";
import AddAddressHook from "../../hooks/user/AddAddressHook";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import UserMapLocation from "./UserMapLocation";
import { useSelector } from "react-redux";

const UserAddAddress = () => {
  const { t } = useTranslation();
  const [
    name,
    zone,
    city,
    address,
    mobile,
    closeSign,
    onChangeName,
    onChangeZone,
    onChangeCity,
    onChangeAddress,
    onChangeMobile,
    onChangeCloseSign,
    onSubmit,
    show,
    handleShow,
    handleClose,
    latitude,
    longitude,
    onChangeLatLang,
    showAddressDetails,
  ] = AddAddressHook(1);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-2">{t("add new address")}</div>
        <Col sm="8">
          <input
            value={name}
            onChange={onChangeName}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("Title nam")}
          />

          {showAddressDetails ? (
            <>
              <textarea
                value={closeSign}
                onChange={onChangeCloseSign}
                className="input-form-area p-2 mt-3"
                rows="2"
                cols="50"
                placeholder={t("nearest mark")}
              />
              <input
                value={mobile}
                onChange={onChangeMobile}
                type="text"
                className="input-form d-block mt-3 px-3"
                placeholder={t("phone")}
              />
              <input
                value={zone}
                onChange={onChangeZone}
                type="text"
                className="input-form d-block mt-3 px-3"
                placeholder={t("region")}
              />
              <input
                value={city}
                onChange={onChangeCity}
                type="text"
                className="input-form d-block mt-3  px-3"
                placeholder={t("city")}
              />
            </>
          ) : (
            ""
          )}

          <textarea
            value={address}
            onChange={onChangeAddress}
            className="input-form-area p-2 my-3"
            rows="4"
            cols="50"
            placeholder={t("Notes")}
          />
          {/* <iframe
            width="100%"
            height="250"
            id="gmap_canvas"
            src={`https://maps.google.com/maps?q=${latitude},${longitude}&iwloc=&output=embed`}
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe> */}

          <UserMapLocation
            latitude={latitude}
            longitude={longitude}
            onPress={onChangeLatLang}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("add adress")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default UserAddAddress;
