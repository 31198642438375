import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { payInvoiceOrder } from "../../redux/actions/orderAction";

const Payment = () => {
  const location = useLocation();
  const { state } = location;

  console.log("state");
  console.log(state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.Moyasar !== undefined) {
      window.Moyasar.init({
        element: ".mysr-form",
        amount: state.amount * 100, // Amount in the smallest currency unit
        currency: "SAR",
        description: state.invoiceNote,
        publishable_api_key: "pk_live_Syx5fJFN1HXj6QvYQjPqvJujdztzjxL379mbiKbM",
        callback_url: "https://raghedaltamuz.com/user/allorders", // Replace with your callback URL
        methods: ["creditcard"], // You can specify the payment methods
        on_completed: async function (payment) {
          console.log("Payment successful:", payment);
          // Handle successful payment
          await dispatch(
            payInvoiceOrder({
              userId: state.userId,
              invoiceUserAdress: state.invoiceUserAdress,
              invoiceUserPhone: state.invoiceUserPhone,
              invoiceUserLocation: "location",
              invoiceNote: state?.invoiceNote,
              products: state.products,
            })
          );
        },
        on_failed: function (error) {
          console.error("Payment failed:", error);
          // Handle failed payment
        },
      });
    }
  }, []);

  return (
    <div className="p-5">
      <div class="mysr-form"></div>;
    </div>
  );
};

export default Payment;
