import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import UserAllOrderCard from "./UserAllOrderCard";
import Cookies from "js-cookie";
import UserGetOrdersHook from "../../hooks/user/UserGetOrdersHook";
import { GiReturnArrow } from "react-icons/gi";

import { t } from "i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState } from "react";

const UserAllOrderItem = ({ orderItem }) => {
  //  const [success,setSuccess] = useState(searchParams.get("success"))
  // console.log('searchParams')
  // console.log(success)
  const [
    username,
    orderData,
    user,
    description,
    show,
    onChangeDescription,
    handleCloseModel,
  ] = UserGetOrdersHook();
  const currencytype = t("SAR");
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const navigate = useNavigate();
  const orderDetails = () => {
    navigate(`/order/details/${orderItem.invoiceId}`);
  };

  return (
    <div className="user-order mt-2" onClick={orderDetails}>
      <Row className="d-flex justify-content-between">
        <Col>
          <div className="py-2 order-title" style={{ color: "#d53a59" }}>
            {t("order number")}#{orderItem.invoiceId} ..{t("done with date")}
            ... {formatDate(orderItem.invoiceDate)}
          </div>
        </Col>
      </Row>

      {/* {orderItem.orderItems
        ? orderItem.orderItems.map((el) => {
            return <UserAllOrderCard key={el._id} item={el} />;
          })
        : null} */}

      <Row
        className="d-flex justify-content-between"
        style={{ cursor: "pointer" }}
      >
        <Col xs="12" className="d-flex">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-inline">{t("phone")}</div>
            <div className="d-inline mx-2 stat">
              [ {orderItem.invoiceUserPhone}]
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-inline">{t("Address")}</div>
            <div className="d-inline mx-2 stat">
              {orderItem.invoiceUserAdress}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-inline">{t("invoiceReceive")} </div>
            <div className="d-inline mx-2 stat">
              {orderItem?.invoiceReceive ? "نعم" : "لا"}
            </div>
          </div>
        </Col>

        <Col xs="12" className="d-flex justify-content-end">
          <div style={{ textAlign: "left" }}>
            <div className="barnd-text mb-3 mx-2">
              {/* {orderItem.totalPrice || 0} {currencytype} */}
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default UserAllOrderItem;
