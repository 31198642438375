import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link, useParams } from "react-router-dom";
import AddToCartHook from "../../hooks/cart/AddToCartHook";
import ViewProductDetails from "../../hooks/product/ViewProductDetailsHooks";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const ProductDescription = () => {
  const { id } = useParams();
  const [productItem, similarProducts] = ViewProductDetails(id);
  const [handleAddToCart, onClickProduct, show, handleClose] = AddToCartHook(
    id,
    productItem
  );

  const { t } = useTranslation();

  return (
    <>
      <>
        <div>
          <Row>
            <Col md="8" className="mt-4">
              <div className="cat-text d-inline">{t("Product Name")}</div>
              <div className="barnd-text d-inline mx-2">
                {productItem.productName}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="8" className="mt-2">
              <div className="cat-text d-inline">
                {t("Product Description")}
              </div>
              <div className="barnd-text d-inline mx-2">
                {productItem.productDescripition}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="8" className="mt-2">
              <div className="cat-text d-inline">{t("Category")}</div>
              <div className="barnd-text d-inline mx-2">
                {productItem.categoryName ? productItem.categoryName : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="8" className="mt-2">
              <div className="cat-text d-inline">{t("company")}</div>
              <div className="barnd-text d-inline mx-2">
                {productItem.companyId ? productItem.companyName : null}
              </div>
            </Col>
          </Row>

          {/* {webSiteData.MULTI_VENDOR === "true" ? (
              <Row className="mt-4">
                <Col md="10">
                  <div className="cat-text"> {t("vendor name")}</div>
                  <Link
                    to={`/${
                      oneProduct.vendor ? oneProduct.vendor.username : ""
                    }`}
                  >
                    <div className="product-description d-inline">
                      {oneProduct.vendor
                        ? oneProduct.vendor.firstname +
                          " " +
                          oneProduct.vendor.lastname
                        : null}
                    </div>
                  </Link>
                </Col>
              </Row>
            ) : null} */}

          <Row className="mt-4">
            <Col md="12">
              <div className="product-price d-inline px-3 p-3 border">
                {productItem.productPrice}{" "}
                <span className="p-1">{t("SAR")}</span>
              </div>
              <div
                onClick={handleAddToCart}
                className="product-cart-add px-3 py-3 d-inline mx-3"
                style={{ backgroundColor: "#d53a59" }}
              >
                {t("Add to cart")}
              </div>
              {/* {oneProduct.countInStock == 0 ? (
                  <div
                    className="my-4 p-2 "
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("product not found in stock")}
                  </div>
                ) : (
                  <div></div>
                )} */}
            </Col>
            <ToastContainer />
          </Row>
        </div>
      </>
    </>
  );
};

export default ProductDescription;
