import { Route, Routes } from "react-router-dom";
import "./App.css";
import NavBarLogin from "./components/utilities/NavBarLogin";
import HomePage from "./pages/Home/HomePage";
import LoginPage from "./pages/Auth/LoginPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import Footer from "./components/utilities/Footer";
import TermsAboutPlicyPage from "./pages/Utilities/TermsAboutPlicyPage";
import NotFoundPage from "./pages/Utilities/NotFoundPage";
import ProductDetailsPage from "./pages/Product/ProductDetailsPage";
import CartPage from "./pages/Cart/CartPage";
import UserProfilePage from "./pages/User/UserProfilePage";
import UserAllAddresPage from "./pages/User/UserAllAddresPage";
import UserAddAddressPage from "./pages/User/UserAddAddressPage";
import ProductsByCategory from "./pages/Product/ProductsByCategory";
import ProductsByCompany from "./pages/Product/ProductsByCompany";
import UserAllOrdersPage from "./pages/User/UserAllOrdersPage";
import OrderDetails from "./components/User/OrderDetails";
import ShopProductPage from "./pages/Product/ShopProductPage";
import Payment from "./components/Cart/Payment";

function App() {
  return (
    <div className="font">
      <NavBarLogin />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/info/:name/:type" element={<TermsAboutPlicyPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />

        <Route path="/cart" element={<CartPage />} />
        <Route path="/user/profile" element={<UserProfilePage />} />
        <Route path="/user/allorders" element={<UserAllOrdersPage />} />

        <Route path="/user/addresses" element={<UserAllAddresPage />} />
        <Route path="/user/add-address" element={<UserAddAddressPage />} />

        <Route path="/products/category/:id" element={<ProductsByCategory />} />
        <Route path="/products/company/:id" element={<ProductsByCompany />} />
        <Route path="/order/details/:id" element={<OrderDetails />} />
        <Route path="/products" element={<ShopProductPage />} />
        <Route path="/payment" element={<Payment />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
