import { GET_POLICY_TERMS_ABOUT } from "../type";
import { useGetData } from "../../database/useGetData";

export const getData = (type) => async (dispatch) => {
  try {
    console.log("type");
    console.log(type);

    let url = `/api/AboutUs/Select/${
      type == 3 ? "AboutUs" : type == 2 ? "RefundPolicy" : "TermsAndConditions"
    }`;

    const response = await useGetData(url);

    dispatch({
      type: GET_POLICY_TERMS_ABOUT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_POLICY_TERMS_ABOUT,
      payload: error,
    });
  }
};
