import React from "react";
import Slider from "../../components/Home/Slider";
// import HomeCategory from "../../Components/Home/HomeCategory";
// import CardProductsContainer from "../../Components/Products/CardProductsContainer";
// import DiscountSection from "../../Components/Home/DiscountSection";
// import CategoryHeader from "../../components/Category/CategoryHeader";
import { useTranslation } from "react-i18next";
// import ViewHomeProductsHooks from "../../hooks/product/ViewHomeProductsHooks";
import LoadingBar from "react-top-loading-bar";
import { Spinner } from "react-bootstrap";
import { FallingLines, ThreeDots } from "react-loader-spinner";
import HomeCategory from "../../components/Home/HomeCategory";
import HomeCompany from "../../components/Home/HomeCompany";
import ViewHomeProductsHooks from "../../hooks/product/ViewHomeProductsHooks";
import CardProductsContainer from "../../components/Products/CardProductsContainer";
import DiscountSection from "../../components/Home/DiscountSection";

const HomePage = () => {
  const { t } = useTranslation();
  const [items, newProducts, agents, fourProducts, ref] =
    ViewHomeProductsHooks();

  return (
    <div className="font" style={{ minHeight: "670px" }}>
      <LoadingBar color="red" ref={ref} shadow={true} height={4} />

      {/*settingsData?.data && settingsData?.data?.showNavHeader === true ? (
        <CategoryHeader />
      ) : null*/}
      <Slider />
      <HomeCompany />
      <HomeCategory />

      {items?.length >= 1 ? (
        <CardProductsContainer
          title={t("Featured Products")}
          // btntitle={t("more")}
          path="products"
          products={items}
        />
      ) : (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={"#d53a59"}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {items?.length >= 1 ? (
        <CardProductsContainer
          title={t("newProducts")}
          // btntitle={t("more")}
          path="newProducts"
          products={newProducts}
        />
      ) : null}

      {agents &&
        items?.length >= 1 &&
        agents.map((el) => (
          <DiscountSection
            image={el.agentImage}
            title={el.agentName}
            company={el.agentCompany}
            phone={el.agentPhone}
            color={"#d53a59"}
            secondaryColor={"#C17785"}
            key={el.agentId}
          />
        ))}

      {fourProducts?.length >= 1 ? (
        <CardProductsContainer products={fourProducts} fourImage={true} />
      ) : null}

      {/*  {settingsData?.data && settingsData?.data?.showCategory === true ? (
        <HomeCategory />
      ) : null}
      {settingsData?.data &&
      settingsData?.data?.showFeaturedProducts === true ? (
        Featureditems?.length >= 1 ? (
          <CardProductsContainer
            title={t("Featured Products")}
            btntitle={t("more")}
            path="products"
            products={Featureditems}
          />
        ) : (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={settingsData?.data?.primaryColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )
      ) : null}

      {adsData.data &&
        adsData.data.map((el) => {
          return el.page === "home_screen" && el.key === 1 ? (
            <DiscountSection
              images={el.images}
              title={el.title}
              color={el.color}
              secondaryColor={el.secondaryColor}
              link={el.link}
              link2={el.link2}
              key={el._id}
            />
          ) : null;
        })}

      <CardProductsContainer
        title={t("Latest Products")}
        btntitle={t("more")}
        path="products"
        products={items}
      />
      {selectedOffers?.length >= 1 ? (
        <CardProductsContainer
          title={t("offers")}
          btntitle={t("more")}
          path="homeOffers"
          products={selectedOffers}
        />
      ) : null}

      {adsData.data &&
        adsData.data.map((el) => {
          return el.page === "home_screen" && el.key === 2 ? (
            <DiscountSection
              images={el.images}
              title={el.title}
              color={el.color}
              secondaryColor={el.secondaryColor}
              link={el.link}
              link2={el.link2}
              key={el._id}
            />
          ) : null;
        })}*/}
    </div>
  );
};

export default HomePage;
