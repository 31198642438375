import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

import CardProductsContainer from "../../components/Products/CardProductsContainer";
import Pagination from "../../components/utilities/Pagination";

// import SideFilter from "../../components/utilities/SideFilter";
// import ViewSearchProductHooks from "../../hooks/product/ViewSearchProductHooks";
import LoadingSpinner from "../../components/utilities/LoadingSpinner";
import baseURL from "../../Api/baseURL";
import ViewAllProductsByCompanyHook from "../../hooks/product/ViewAllProductsByCompanyHook";

const ProductsByCompany = () => {
  const { id } = useParams();
  const [items, pagination, onPress, loadingSpinner] =
    ViewAllProductsByCompanyHook(id);

  const location = useLocation();
  const { state } = location;

  console.log("state");
  console.log(state);

  // const [
  //   items,
  //   pagination,
  //   onPress,
  //   getProduct,
  //   result,
  //   offers,
  //   selectedCategorySearch,
  //   selectedBrandSearch,
  //   search,
  //   onChangeSetMinPrice,
  //   onChangeSetMaxPrice,
  //   minPrice,
  //   maxPrice,
  //   loadingSpinner,
  // ] = ViewSearchProductHooks("", undefined, id);
  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  return (
    <div style={{ minHeight: "670px" }}>
      {/* <Helmet>
        <title>
          {`  ${items[0]?.category.nameAr || " "} ${items[0] ? "-" : " "} ${
            settingsRes.data?.titleAr
          }`}{" "}
        </title>
      </Helmet> */}
      {/* <CategoryHeader /> */}
      <div
        style={{
          background: "#f004",
          height: "296px",
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            height: "296px",
            alignItems: "center",
          }}
        >
          <img
            alt="category"
            src={baseURL.getUri() + state?.img}
            width="210px"
            className="mx-3"
            style={{ objectFit: "contain" }}
          />
          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
            {state?.title}
          </div>
        </div>
      </div>
      <Container>
        <Row>
          {/* <Col md="2" sm="2" className="d-flex ">
            <SideFilter
              props={{
                selectedCategorySearch,
                selectedBrandSearch,
                search,
                onChangeSetMinPrice,
                onChangeSetMaxPrice,
                hideCategory,
              }}
            />
          </Col> */}
          <Col md="12" sm="12" xs="12">
            {loadingSpinner === true ? (
              <LoadingSpinner />
            ) : (
              <CardProductsContainer products={items} title="" btntitle="" />
            )}
          </Col>
        </Row>
        <Pagination pageCount={pageCount} onPress={onPress} />
      </Container>
    </div>
  );
};

export default ProductsByCompany;
